import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { errorPage } from '../public/assets/images';

const ErrorPage = () => {
  return (
    <section className="h-screen w-screen flex items-center justify-center flex-col">
      <Image src={errorPage} />
      <p className="text-base lg:text-[32px] font-medium mt-12">
        {"Aww...don't cry. It's just a 404 error"}
      </p>
      <Link href="/">
        <button className="btn-primary px-5 py-4 mt-8 hover:opacity-95 transition-all ease-linear duration-500">
          Click to Go to Home Page
        </button>
      </Link>
    </section>
  );
};

export default ErrorPage;
